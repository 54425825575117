const API_VERSION = 'v1';

const config = {
  env: process.env.NODE_ENV || 'development',
  baseURL: process.env.REACT_APP_BASE_URL,
  idleLogoutTime: 30 * 60 * 1000,
  endpoints: {
    login: `/${API_VERSION}/auth/login`,
    clientList: `/${API_VERSION}/clients`,
    refresh: '/v0/refresh/token',

    // users
    users: `/${API_VERSION}/users`,
    usersWithClients: `/${API_VERSION}/users/clients`,
    updatePassword: `/${API_VERSION}/users/:userId/password`,
    userDetail: `/${API_VERSION}/users/:userId`,
    userMFAQR: `/${API_VERSION}/users/:userId/mfa`,

    // insurance
    insuranceCategories: '/v0/insuranceCategories',
    insuranceMapping: '/v0/mappingInsuranceByClientId/:clientId',
    exportInsurance: `/${API_VERSION}/mapping/insurance-mapping/export?client_id=:clientId`,

    // transaction
    financialCategories: '/v0/financialCategories',
    transactionMapping: '/v0/transactionMappingByClientId/:clientId',
    exportTransaction: `/${API_VERSION}/mapping/transaction-mapping/export?client_id=:clientId`,

    // account information
    accountInformation:
      '/v0/accountDetails/clientId/:clientId/acctNumber/:accountNumber',
    crownComputedInformation:
      '/v0/crownComputed/clientId/:clientId/acctNumber/:accountNumber',
    accountFinancials:
      '/v0/financialDetailByClientId/:clientId/accountNumber/:accountNumber',
    accountBillReference:
      '/v0/billReferenceDetailByClientId/:clientId/accountNumber/:accountNumber',
    accountInsurance:
      '/v0/insuranceDetailByClientId/:clientId/accountNumber/:accountNumber',
    dataIntegrity:
      '/v0/dataIntegrity/byClientId/:clientId/projectId/:accountNumber',
    ruleHits: '/v0/ruleHits/byClientId/:clientId/projectId/:accountNumber',

    // account information v1 endpoints
    accountInformationV1: `/${API_VERSION}/accounts/:accountId?client_id=:clientId`,
    crownComputedDatesV1: `/${API_VERSION}/accounts/:accountId/computed-dates?client_id=:clientId`,
    crownComputedTotalsV1: `/${API_VERSION}/accounts/:accountId/computed-totals?client_id=:clientId`,
    dataIntegrityV1: `/${API_VERSION}/accounts/:accountId/data-integrity?client_id=:clientId`,
    ruleHitsV1: `/${API_VERSION}/accounts/:accountId/rule-hits?client_id=:clientId`,
    accountFinancialsV1: `/${API_VERSION}/accounts/:accountId/account-financials?client_id=:clientId`,
    accountBillReferenceV1: `/${API_VERSION}/accounts/:accountId/bill-reference?client_id=:clientId`,
    accountInsuranceV1: `/${API_VERSION}/accounts/:accountId/account-insurance?client_id=:clientId`,
    exportAccountData: `/${API_VERSION}/accounts/:accountId/:tableName/export?client_id=:clientId`,
    crownComputedStatistics: `/${API_VERSION}/accounts/:accountId/computed-statistics?client_id=:clientId`,
    matchedDataHintRules: `/${API_VERSION}/accounts/:accountId/data-hints/matches?client_id=:clientId`,

    // rules Library v1 endpoints
    rulesV1: `/${API_VERSION}/rules?client_id=:clientId`,
    updateRuleV1: `/${API_VERSION}/rules/:ruleId`,
    createRuleV1: `/${API_VERSION}/rules`,
    ruleCategoriesV1: `/${API_VERSION}/rule-categories`,

    // project Analysis v1 endpoints
    projectsV1: `/${API_VERSION}/projects?client_id=:clientId`,
    createProjectV1: `/${API_VERSION}/projects`,
    updateProjectV1: `/${API_VERSION}/projects/:projectId`,
    exportProjectV1: `/${API_VERSION}/projects/:projectId/reports`,

    // data chooser v1 endpints
    filesV1: `/${API_VERSION}/data-chooser?client_id=:clientId`,
    connectFilesV1: `/${API_VERSION}/data-chooser/connect`,
    computeStatsV1: `/${API_VERSION}/data-chooser/compute-stats`,
    aggregateStatisticsV1: `/${API_VERSION}/data-chooser/:projectId/aggregate-statistics?client_id=:clientId`,
    computedStatisticsV1: `/${API_VERSION}/data-chooser/:projectId/computed-statistics?client_id=:clientId`,

    // mapping endpoints
    insuranceMappingDataV1: `/${API_VERSION}/mapping/insurance-mapping?client_id=:clientId`,
    transactionMappingDataV1: `/${API_VERSION}/mapping/transaction-mapping?client_id=:clientId`,

    // validation checker endpoints
    runValidations: `/${API_VERSION}/validation-check`,
    validationChecker: `/${API_VERSION}/validation-check/:projectId`,
    validationCheckerStatus: `/${API_VERSION}/validation-check/status/:projectId`,

    // rule library endpoints
    getAssociatedRules: `/${API_VERSION}/rule-library/:projectId?client_id=:clientId`,
    connectRules: `/${API_VERSION}/rule-library/:projectId`,

    // qa page endpoints
    getQAData: `/${API_VERSION}/qa/:projectId/results`,
    trashAndRunAll: `/${API_VERSION}/qa/run-all`,
    runSingleRule: `/${API_VERSION}/qa/run-rule`,
    deleteSingleRule: `/${API_VERSION}/qa/rule/:projectRuleBaseId`,
    getQARuleDetails: `/${API_VERSION}/qa/:projectRuleBaseId/details`,
    exportQARuleDetails: `/${API_VERSION}/qa/:projectRuleBaseId/details/export`,

    // statement page endpoints
    statementCategories: `/${API_VERSION}/statement/statuses`,
    getProjectStatement: `/${API_VERSION}/statement/:projectId`,
    updateProjectStatement: `/${API_VERSION}/statement/:projectId`,

    // job related endpoints
    jobData: `/${API_VERSION}/jobs`,
    jobStatus: `/${API_VERSION}/jobs/:projectId`,

    // Publisher endpoints
    getPublisherData: `/${API_VERSION}/publisher/:projectId/rules`,
    exportReport: `/${API_VERSION}/publisher/:projectId/export`,

    // clients v1 endpoints
    clients: `/${API_VERSION}/clients`,

    // client data overview
    clientDataOverview: `/${API_VERSION}/clients/:clientId/overview`,

    // uroles
    uroles: `/${API_VERSION}/uroles`,
    urolePermissions: `/${API_VERSION}/uroles/:uroleId/permissions`,

    // MFA
    rememberDevice: `/${API_VERSION}/mfa/remember-device`,

    // DATA HINTS
    dataHints: `/${API_VERSION}/data-hints`,
    executeDataHints: `/${API_VERSION}/data-hints/execute`,
    dataHintsValidationResult: `/${API_VERSION}/data-hint-validation/matched-rules`,

    // WORK TRACKERS
    workTrackers: `/${API_VERSION}/work-trackers`,
    workTrackersCountAgg: `/${API_VERSION}/work-trackers/weekly-counts`,

    // Work Tracker Action
    workTrackerAction: `/${API_VERSION}/work-tracker-actions`,

    // Transaction Combo Endpoint
    transactionCombo: `/${API_VERSION}/transaction-combo/client/:clientId`,

    // ETL File load status
    etlFileLoad: `/${API_VERSION}/etl-fileload`,

    // Locations
    locations: `/${API_VERSION}/clients/:clientId/locations`,

    // account queue
    accountQueue: `/${API_VERSION}/clients/:clientId/account-queue/filter`,
  },
};

export default config;
