import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Icon from 'components/common/icons/Icons';
import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import Dropdown from 'components/common/dropdown/Dropdown';
import StatCard from 'components/common/stat-card/StatCard';
import SwitchButton from 'components/common/switch/SwitchButton';
import EmptyProjectPage from 'components/common/emptypage/EmptyProjectPage';
import CustomColumnSelector from 'components/common/table/CustomColumnSelector';

import { tableConfig } from './tableConfig';
import { columnConfig, ETL_FILELOAD_ID, PROJECT_ID } from './columnConfig';
import {
  computedStatCardConfig,
  aggregateStatCardConfig,
} from './statCardConfig';

import * as routes from 'constants/routes';
import { VALIDATION } from 'constants/projectStatuses';
import { DEFAULT_NULL_STRING } from 'constants/formatConfig';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';
import { CHECKBOX_COLUMN } from 'components/common/table/constants';
import {
  ERROR,
  ANALYSING,
  JOB_FAILED,
  JOB_COMPLETED,
  JOB_SUBMITTED,
  NO_JOB_STARTED,
} from 'constants/jobResponses';

import { getJobStatus } from 'services/jobs';
import { updateProject } from 'services/projectAnalysis';
import * as dataChooserService from 'services/dataChooser';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
} from 'actions/clientAction';

import { formatDateWithTime } from 'utils/common/formatter';
import ContinueButton from 'components/common/continueButton/continueButton';

const mapStateToProps = (state) => {
  const { selectedClientId, clientList, selectedProject } = state;

  return { selectedClientId, clientList, selectedProject };
};

class DataChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      navigateTo: '',
      hasError: false,
      errorMessage: '',
      selectedData: [],
      isLoading: false,
      isComputing: false,
      selectedColumns: [],
      computedStatistics: {
        recordCount: null,
        balanceCount: null,
      },
      aggregateStatistics: {
        maxInsertDate: '',
        minInsertDate: '',
        recordCount: null,
      },
      jobStatus: null,
      showAllLinkedFiles: false,
      updatedColumnConfig: {
        field: '',
        isShownByDefault: false,
      },
      isConnecting: false,
    };
  }

  async componentDidMount() {
    const { selectedProject } = this.props;

    this.setState({
      selectedColumns: columnConfig
        .filter((config) => config.field !== CHECKBOX_COLUMN)
        .filter((config) => config.isShownByDefault)
        .map((col) => col.field),
    });

    if (selectedProject.projectId) {
      this.setState({
        isLoading: true,
      });

      await this.getFilesData();
      await this.getComputedData();

      if (this.props.clientList.length === 0) {
        await this.updateClientList();
      }
    }
  }

  getFilesData = async () => {
    const { selectedClientId, selectedProject } = this.props;

    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      const data = await dataChooserService.getFiles(selectedClientId);

      if (selectedProject.projectId) {
        const rowsToBeSelected = data
          .filter(
            (singleData) =>
              singleData[PROJECT_ID] === parseInt(selectedProject.projectId)
          )
          .map((singleData) => singleData[ETL_FILELOAD_ID]);

        this.setState({
          selectedData: rowsToBeSelected,
        });
      }

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  sanitizeResponse = (data, cardConfig) => {
    let sanitizedData = { ...data };

    Object.keys(cardConfig).forEach((configKey) => {
      const oldKey = cardConfig[configKey].key;
      sanitizedData[configKey] = sanitizedData[oldKey];
      delete sanitizedData[oldKey];
    });

    return sanitizedData;
  };

  recomputeStatistics = async () => {
    const { selectedClientId, selectedProject } = this.props;

    this.setState({
      isComputing: true,
      hasError: false,
      errorMessage: '',
    });

    try {
      await dataChooserService.computeStatistics(
        selectedProject.projectId,
        selectedClientId
      );

      this.setState({
        isComputing: false,
        jobStatus: JOB_SUBMITTED,
      });

      this.resetComputedData();
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
        isComputing: false,
      });
    }
  };

  getComputedData = async () => {
    const { selectedClientId, selectedProject } = this.props;

    try {
      this.setState({
        isLoading: true,
      });

      const response = await getJobStatus(selectedProject.projectId);
      const statusData = response.data_chooser;

      if (!statusData) {
        this.setState({
          jobStatus: NO_JOB_STARTED,
        });

        return;
      }
      this.setState({
        jobStatus: statusData.job_status,
      });

      if (statusData.job_status === JOB_COMPLETED) {
        const computedData = await dataChooserService.getComputedStatistics(
          selectedProject.projectId,
          selectedClientId
        );
        const aggregateData = await dataChooserService.getAggregateStatistics(
          selectedProject.projectId,
          selectedClientId
        );

        this.setState({
          computedStatistics: this.sanitizeResponse(
            computedData,
            computedStatCardConfig
          ),
          aggregateStatistics: this.sanitizeResponse(
            aggregateData,
            aggregateStatCardConfig
          ),
        });
      }
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.setState({
      rowData: {},
      navigateTo: routes.PROJECT_ANALYSIS,
    });

    this.props.updateClientID(clientId);
    this.props.clearSelectedProject();
  };

  getFilteredData = () => {
    const { data, showAllLinkedFiles } = this.state;
    const { selectedProject } = this.props;

    if (!selectedProject.projectId) {
      return [];
    }

    if (!showAllLinkedFiles) {
      return data.filter(
        (row) =>
          !row.project_id ||
          row.project_id === DEFAULT_NULL_STRING ||
          parseInt(row.project_id) === parseInt(selectedProject.projectId)
      );
    }

    return data;
  };

  handleShowAllFilesToggle = () => {
    this.setState({
      showAllLinkedFiles: !this.state.showAllLinkedFiles,
    });
  };

  handleColumnSelect = (selectedColumnField) => {
    const { selectedColumns } = this.state;
    const selected = selectedColumns.includes(selectedColumnField);

    if (selected) {
      this.setState({
        selectedColumns: selectedColumns.filter(
          (col) => col !== selectedColumnField
        ),
      });
    }

    if (!selected) {
      this.setState({
        selectedColumns: [...selectedColumns, selectedColumnField],
      });
    }

    this.setState({
      updatedColumnConfig: {
        field: selectedColumnField,
        isShownByDefault: !selected,
      },
    });
  };

  handleRowSelection = (selectedData) => {
    this.setState({
      selectedData: selectedData.map((row) => row[ETL_FILELOAD_ID]),
    });
  };

  handleConnectToProject = async () => {
    const { selectedData } = this.state;
    const { selectedProject, selectedClientId } = this.props;

    this.setState({
      isConnecting: true,
    });

    try {
      await dataChooserService.connectToProject(
        selectedProject.projectId,
        selectedClientId,
        selectedData
      );

      await dataChooserService.computeStatistics(
        selectedProject.projectId,
        selectedClientId
      );

      this.setState({
        isConnecting: false,
        jobStatus: JOB_SUBMITTED,
        selectedData,
      });
      this.resetComputedData();
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isConnecting: false,
      });
    }
  };

  handleNextStep = async () => {
    const { selectedClientId, selectedProject, updateSelectedProject } =
      this.props;

    if (selectedProject.projectId) {
      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: VALIDATION.toUpperCase(),
          }
        );

        updateSelectedProject({
          ...selectedProject,
          status: VALIDATION.toUpperCase(),
          lastUpdateDate: formatDateWithTime(data.update_date),
        });

        this.setState({
          navigateTo: routes.VALIDATION_CHECKER,
        });
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DEFAULT_ERROR_MESSAGE;

        this.setState({
          hasError: true,
          errorMessage,
          isLoading: false,
        });
      }
    }
  };

  resetComputedData = () => {
    this.setState({
      computedStatistics: {
        recordCount: null,
        balanceCount: null,
      },
      aggregateStatistics: {
        maxInsertDate: '',
        minInsertDate: '',
        recordCount: null,
      },
    });
  };

  getProjectDisplayStatus = (projectStatus, jobStatus) => {
    if (jobStatus === JOB_SUBMITTED) {
      return ANALYSING;
    }

    if (jobStatus === JOB_FAILED) {
      return ERROR;
    }

    if (!projectStatus) {
      return '';
    }

    return projectStatus;
  };

  handleBadgeClick = () => {
    window.open(routes.JOBS, '_blank');
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      jobStatus,
      navigateTo,
      isComputing,
      errorMessage,
      isConnecting,
      selectedData,
      selectedColumns,
      showAllLinkedFiles,
      computedStatistics,
      aggregateStatistics,
      updatedColumnConfig,
    } = this.state;
    const { clientList, selectedClientId, selectedProject } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );
    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });
    const projectDisplayName = selectedProject.projectName || '';
    const tableData = this.getFilteredData();
    const totalAllFilesCount = data.length;

    const badgeStatus = this.getProjectDisplayStatus(
      selectedProject.status,
      jobStatus
    );
    const badgeClass = classNames({
      badge: true,
      error: badgeStatus === ERROR,
      highlight: badgeStatus === ANALYSING,
    });

    return navigateTo ? (
      <Navigate to={navigateTo} replace={true} />
    ) : (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-1x">
            <h1>
              Data Chooser{' '}
              {projectDisplayName && <span>({projectDisplayName})</span>}
            </h1>
            {badgeStatus && (
              <div
                role={'button'}
                className={`${badgeClass} badge-lg ml-5x mt-3x`}
                onClick={this.handleBadgeClick}
              >
                {badgeStatus}
              </div>
            )}

            <button
              className="btn-has-icon ml-5x mr-auto mt-3x"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              <Icon
                icon="refresh"
                size={12}
                color="#546071"
                className="mr-2x"
              />
              Refresh
            </button>

            <div className="color-grey--60 mt-4x">
              Last Updated : {selectedProject.lastUpdateDate}
            </div>
          </div>
        </div>

        {isLoading && <Loader isFullScreen={true} />}
        {selectedProject.projectId ? (
          <div>
            <div className="d-flex justify-content-between align-items-center bg-white--base py-2x px-4x mb-1x border-radius-4">
              <div className="switcher-filters">
                <SwitchButton
                  key={'showAllFiles'}
                  value={showAllLinkedFiles}
                  labelText={'Show All'}
                  badgeCount={totalAllFilesCount}
                  onClick={this.handleShowAllFilesToggle}
                />
              </div>
              <CustomColumnSelector
                columnConfig={columnConfig}
                handleSelect={this.handleColumnSelect}
                selectedColumns={selectedColumns}
                className={'dropdown__right'}
              />
            </div>

            <div
              className="d-flex flex-direction-column gap-3x"
              style={{ height: 'calc(100vh - 177px)' }}
            >
              <div className="table table-50vh has-box-shadow">
                <Table
                  className="has-box-shadow"
                  tableConfig={tableConfig}
                  columnConfig={columnConfig}
                  pagination={false}
                  selectedRows={selectedData}
                  onRowSelection={this.handleRowSelection}
                  data={tableData}
                  selectedColumns={selectedColumns}
                  updatedColumnConfig={updatedColumnConfig}
                />
              </div>

              <div className="d-flex flex-direction-column align-items-end">
                <button
                  className="btn btn-primary has-loader"
                  onClick={this.handleConnectToProject}
                >
                  Connect to the Project
                  {isConnecting && <span className="spinner" />}
                </button>
              </div>

              <div className="white__box table-50vh pt-2x pb-3x px-10x">
                <h2 className="color-primary--base">Statistics</h2>
                <p>
                  The data that is shown below is generated for the rows that
                  are selected for calculation.
                </p>

                <div className="row mt-6x">
                  <div className="col-4">
                    <StatCard
                      title="Project Aggregated Statistics"
                      data={aggregateStatistics}
                      cardConfig={aggregateStatCardConfig}
                    />
                  </div>
                  <div className="col-4">
                    <StatCard
                      title="Project Computed Statistics"
                      data={computedStatistics}
                      cardConfig={computedStatCardConfig}
                    />
                  </div>
                  <div className="col-4">
                    <button
                      className="btn-outlined btn-evergreen--outlined has-loader"
                      onClick={this.recomputeStatistics}
                    >
                      Re-compute Project Analysis
                      {isComputing && <span className="spinner" />}
                    </button>
                  </div>
                </div>
              </div>

              <ContinueButton onClick={this.handleNextStep} />

            </div>
          </div>
        ) : (
          <EmptyProjectPage pageName="Data Chooser" />
        )}

        {hasError && (
          <Toast
            hasError={hasError}
            title={errorMessage}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

DataChooser.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  updateSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
  updateSelectedProject,
})(DataChooser);
