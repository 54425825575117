import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import SelectBox from 'components/common/select/Select';
import Dropdown from 'components/common/dropdown/Dropdown';
import EmptyPage from 'components/common/emptypage/EmptyPage';

import { tableConfig } from './tableConfig';
import { columnConfig } from './columnConfig';

import { getJobData } from 'services/jobs';
import { getAllClients } from 'services/client';

import {
  updateClientID,
  updateClientList,
  clearSelectedProject,
} from 'actions/clientAction';

import { DEFAULT_NULL_STRING } from 'constants/formatConfig';
import { DEFAULT_ERROR_MESSAGE } from 'constants/errorMessages';

const mapStateToProps = (state) => {
  const { selectedProject, selectedClientId, clientList } = state;

  return { selectedProject, selectedClientId, clientList };
};

class Jobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      hasError: false,
      errorMessage: '',
      isLoading: false,
      hasNewData: false,
      selectedResults: '',
      projectIdOptions: [],
      isSelectedResultError: false,
      selectedProjectId: this.props.selectedProject.projectId || '',
    };
  }

  async componentDidMount() {
    await this.loadJobsData();
    await this.loadProjectIdOptions();

    if (this.props.clientList.length === 0) {
      await this.updateClientList();
    }
  }

  loadJobsData = async (selectedClientId) => {
    const { selectedProjectId } = this.state;
    if (!selectedClientId && !this.props.selectedClientId) {
      return;
    }

    let clientId = selectedClientId || this.props.selectedClientId;
    let projectId = selectedClientId ? '' : selectedProjectId;

    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: '',
      selectedResults: [],
      selectedProjectId: projectId,
      isSelectedResultError: false,
    });

    try {
      const data = await getJobData(clientId, projectId);

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  updateClientList = async () => {
    const data = await getAllClients();

    if (data) {
      this.props.updateClientList(data);
    }
  };

  loadProjectIdOptions = async (selectedClientId) => {
    let clientId = selectedClientId || this.props.selectedClientId;

    if (!clientId) {
      return;
    }

    this.setState({
      isLoading: true,
      projectIdOptions: [],
    });

    try {
      let fetchedOptions = [];
      const data = await getJobData(clientId);

      data.forEach((item) => {
        const { project_id } = item;

        if (
          !item ||
          !project_id ||
          fetchedOptions.find((option) => option.value === project_id)
        ) {
          return;
        }

        fetchedOptions.push({
          label: project_id,
          value: project_id,
        });
      });

      const sortedOptions = fetchedOptions.sort((a, b) => a.value - b.value);

      this.setState({
        isLoading: false,
        projectIdOptions: sortedOptions,
      });
    } catch (error) {
      const errorMessage = error.response.data.detail || DEFAULT_ERROR_MESSAGE;

      this.setState({
        errorMessage,
        hasError: true,
        isLoading: false,
      });
    }
  };

  getProjectIdOptions = (data) => {
    let projectIdOptions = [];

    if (!data) {
      return projectIdOptions;
    }

    data.forEach((item) => {
      const { project_id } = item;

      if (
        !item ||
        !project_id ||
        projectIdOptions.find((option) => option.value === project_id)
      ) {
        return;
      }

      projectIdOptions.push({
        label: project_id,
        value: project_id,
      });
    });

    return projectIdOptions.sort((a, b) => a.value - b.value);
  };

  handleSelectClient = (event) => {
    const clientId = event.target.id;

    this.props.updateClientID(clientId);
    this.loadJobsData(clientId);
    this.loadProjectIdOptions(clientId);
  };

  handleLogClick = (_, cell) => {
    const rowData = cell.getRow().getData();
    const { project_job_logs } = rowData;
    let results = [];

    if (project_job_logs && project_job_logs !== DEFAULT_NULL_STRING) {
      results = project_job_logs;
    }

    this.setState({
      isSelectedResultError: false,
      selectedResults: results.join('\n\n'),
    });
  };

  handleErrorClick = (_, cell) => {
    const rowData = cell.getRow().getData();
    const { project_job_errors } = rowData;
    let results = [];

    if (project_job_errors && project_job_errors !== DEFAULT_NULL_STRING) {
      results = project_job_errors;
    }

    this.setState({
      isSelectedResultError: true,
      selectedResults: results.join('\n'),
    });
  };

  handleSelectProject = (selectedOption) => {
    this.setState({
      selectedProjectId: selectedOption.value,
    });
  };

  handleSubmit = () => {
    this.loadJobsData();
    this.setState({
      hasNewData: !this.state.hasNewData,
    });
  };

  resetError = () => {
    this.setState({
      hasError: false,
      errorMessage: '',
    });
  };

  render() {
    const {
      data,
      hasError,
      isLoading,
      hasNewData,
      errorMessage,
      selectedResults,
      projectIdOptions,
      selectedProjectId,
      isSelectedResultError,
    } = this.state;
    const { clientList, selectedClientId } = this.props;

    const selectedClient = clientList.find(
      (client) => parseInt(client.client_id) === parseInt(selectedClientId)
    );

    const displayName = selectedClient
      ? selectedClient.display_name
      : 'Select the Client';
    const dropdownItems = clientList.map((client) => {
      return {
        id: client.client_id,
        value: client.display_name,
      };
    });

    const selectedOption =
      projectIdOptions.find((option) => option.value === selectedProjectId) ||
      null;

    const submitButtonClassname = classNames({
      'btn btn-primary': true,
      disabled: !selectedProjectId,
    });

    return (
      <>
        <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
          <div className="profile mb-3x ml-auto">
            <Dropdown
              label={displayName}
              dropdownItems={dropdownItems}
              onClick={this.handleSelectClient}
            />
          </div>
          <div className="d-flex justify-content-between align-items-end mb-3x">
            <h1>ETL Jobs</h1>

            <form
              action=""
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="d-flex gap-3x">
                <SelectBox
                  options={projectIdOptions}
                  value={selectedOption}
                  onChange={this.handleSelectProject}
                  placeholder="Select the project"
                  className="select__fixed__width"
                />

                <button
                  className={submitButtonClassname}
                  onClick={this.handleSubmit}
                  disabled={!selectedProjectId}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {selectedClientId ? (
          <>
            <div
              className="d-flex flex-direction-column"
              style={{ height: 'calc(100vh - 143px)' }}
            >
              <div className="table table-25vh has-box-shadow mb-6x">
                <Table
                  data={data}
                  hasNewData={hasNewData}
                  columnConfig={columnConfig}
                  tableConfig={tableConfig}
                  handleLogClick={this.handleLogClick}
                  handleErrorClick={this.handleErrorClick}
                />
              </div>

              <div className="textarea__box">
                <h4 className="title-18 my-3x">
                  Results | {isSelectedResultError ? 'Errors' : 'Logs'}
                </h4>

                <textarea
                  className=""
                  disabled={true}
                  value={selectedResults}
                  onChange={() => {}}
                />
              </div>
            </div>
          </>
        ) : (
          <EmptyPage selectionParameters={'client'} pageName={' '} />
        )}

        {isLoading && <Loader isFullScreen={true} />}
        {hasError && (
          <Toast
            hasError={hasError}
            title={errorMessage}
            handleClose={this.resetError}
          />
        )}
      </>
    );
  }
}

Jobs.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedProject: PropTypes.object,
  clearSelectedProject: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.object),
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, {
  updateClientID,
  updateClientList,
  clearSelectedProject,
})(Jobs);
